.audit-log-filters {
  margin-bottom: 16px;

  > .range-buttons {
    margin-right: 16px;
  }

  .footer {
    > button {
      margin-right: 16px;
    }
    margin-top: 16px;
  }
}
.dynamic-delete-button {
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.load-more {
  width: 100%;
}

