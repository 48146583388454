.canvus-header {
  .logo {
    width: 48px;
    height: 48px;
  }

  // Layout logo to left
  > a {
    float: left;
  }

  // Layout user avatar to right
  .user-menu-container {
    float: right;
  }

  // Fixes <ul> elements portruding down from the header
  ul {
    height: 64px;
  }

  // Changes spacing between the logo and the navigation menu
  .ant-menu,
  .ant-menu-sub {
    padding-left: 25px;
  }
}

