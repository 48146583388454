.ant-layout-header {
  padding: 0 8px 0 8px;

  &.canvas-page {
    padding: 0;
  }
}

.ant-layout-content.scrollable {
  overflow: scroll;
}

.site-layout-background {
  min-height: 100vh;
  background: white;
}

.site-layout {
  background: white;
}

