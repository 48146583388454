.filter-input {
  margin-bottom: 16px;
}

.table-header {
  display: flex;
}

.inline-button {
  margin-left: 16px;
}

