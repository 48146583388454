@import "~antd/lib/style/themes/default.less";
@import "../colors.less";

@tile-border-width: 1px;
@tile-border-radius: 4px;

// Use fixed header height to ensure all tile headers look the same (different
// header content for canvases and folders)
@tile-header-height: 64px;
@tile-header-padding: 8px;

// Size of the canvas previews stored on the server
@canvas-preview-width: 384;
@canvas-preview-height: 230;
@canvas-preview-aspect: calc(@canvas-preview-width / @canvas-preview-height);

// Center the loading spinner on screen
#root {
  height: 100%;

  section.ant-layout {
    height: 100%;
  }
}

.two-column-layout {
  // Viewport height - header height
  height: calc(100vh - 64px);
  display: flex;

  > .left-panel {
    width: 300px;
    height: 100%;
    overflow-x: scroll;

    padding-top: 16px;
    border-right: 1px solid @gray-5;
  }

  > .right-panel {
    width: 100%;
    height: 100%;
    padding: 16px 16px 0px 16px;

    > .folder-view-container {
      display: flex;
      flex-direction: column;
      height: 100%;

      > .search-row {
        margin-bottom: 8px;
      }

      > .toolbar {
        align-items: center;
        border-bottom: 1px solid @gray-5;

        padding-bottom: 8px;

        // Truncate long entries in the breadcrumbs using ellipsis
        .ant-breadcrumb-link {
          max-width: 150px;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .ant-breadcrumb-separator {
          display: inline-block;
          overflow: hidden;
        }

        > .folder-name {
          margin: auto 20px auto 0px;
        }
        > .button-group-1 {
          margin-left: auto;
        }

        > .ant-divider {
          margin-top: 0px;
          margin-bottom: 0px;
        }
      }

      > .folder-view-grid {
        overflow-y: scroll;
        flex-grow: 1;

        // Center the message about empty folders
        &.empty {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        // Don't allow text selection in children (looks bad with shift-click to multi-select)
        * {
          user-select: none;
        }

        > .grid {
          padding: 16px 0px;

          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));

          gap: 16px;
          justify-content: space-between;

          > .tile {
            display: flex;
            flex-direction: column;

            > .header {
              width: 100%;
              height: @tile-header-height;
              padding: @tile-header-padding;

              border-radius: 4px 4px 0 0;
              border-width: @tile-border-width;
              border-style: solid;
              border-color: @gray-5;

              display: flex;
              flex-shrink: 0;
              justify-content: space-between;

              > span {
                margin-top: auto;
                margin-bottom: auto;
                &.info {
                  max-width: 80%;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }

              .modified {
                color: rgba(0, 0, 0, 0.45);
              }

              .ant-btn {
                margin: auto;
              }
            }

            &.selected > .header {
              color: white;
              background-color: #1890ff;
              border-color: #1890ff;

              .modified {
                color: white;
              }

              .anticon-ellipsis {
                color: white;
              }
            }

            &.selected > .resource-preview {
              border-color: #1890ff;
            }
          }
        }
      }

      > .folder-view-list {
        overflow-y: scroll;
        flex-grow: 1;

        // Center the message about empty folders
        &.empty {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        // Don't allow text selection in children (looks bad with shift-click to multi-select)
        * {
          user-select: none;
        }

        // Hide the checkbox column in ant table to match our design
        .ant-table-selection-col,
        .ant-table-selection-column {
          display: none;
        }
      }

      > .footer {
        display: flex;
        justify-content: space-between;
        padding: 8px 0px;

        border-top: 1px solid @gray-5;
      }
    }
  }
}

.resource-preview {
  display: flex;
  flex-direction: column;

  position: relative;

  border-style: solid;
  border-width: 0px @tile-border-width @tile-border-width @tile-border-width;
  border-radius: 0px 0px @tile-border-radius @tile-border-radius;
  border-color: @gray-5;

  > * {
    border-radius: 0px 0px calc(@tile-border-radius - @tile-border-width)
      calc(@tile-border-radius - @tile-border-width);
  }

  > .tags {
    position: absolute;
    top: 8px;
    right: 0px;
  }

  &.folder {
    > span {
      display: flex;

      justify-content: center;
      align-items: center;
      width: 100%;
      aspect-ratio: @canvas-preview-aspect;
      margin: auto;
      font-size: 48pt;
      color: @gray-8;
      background-color: @gray-4;
    }
  }

  &.canvas {
    width: 100%;

    img {
      border-radius: 0px 0px calc(@tile-border-radius - @tile-border-width)
        calc(@tile-border-radius - @tile-border-width);
    }
  }
}

.ant-tree-directory {
  width: max-content;
  min-width: 100%;
}

// Visualize drop target on folder tree with primary color
// TODO: this is not very pretty, but there doesn't seem to be a way to access
// the ancestor DOM nodes from React to make it prettier.
span.drop-target {
  color: @white;
  background-color: @primary-color;
}

.permission-modal {
  .ant-modal-body {
    padding: 16px;
  }

  .ant-modal-confirm-btns {
    float: unset;
    display: flex;
    justify-content: center;
  }
}

.copy-resource-wrapper {
  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-footer {
    display: flex;
  }

  .copy-resource-container {
    overflow: auto;
    height: 60vh;
  }
}

// Center progress indicator in the dialog
.copy-resource-container.copying {
  display: flex;

  > .ant-progress {
    margin: auto;
  }
}

.resource-details-container {
  display: flex;
  flex-direction: column;

  > .resource-preview {
    border-style: none;

    &.canvas img {
      border-radius: 0px;
    }

    &.folder span {
      border-radius: 0px;
    }
  }

  > .ant-image {
    margin-bottom: 16px;
  }

  > .title {
    margin-top: 8px;
    text-align: center;
  }

  > .row {
    display: flex;
    justify-content: space-between;
  }
}

.details-modal {
  .ant-modal-body {
    padding: 16px;
  }

  .ant-modal-confirm-btns {
    display: none;
  }
}

.create-resource-modal,
.rename-resource-modal {
  .ant-modal-body {
    padding: 16px;
  }

  .ant-modal-confirm-btns {
    margin-top: 0px;
  }
}

