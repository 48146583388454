.permissions-wrapper {
  display: flex;
  flex-direction: column;

  > .table-header-row {
    display: flex;
    margin-right: -8px;
    align-items: center;

    > * {
      margin-right: 8px;
    }

    > .select-link-permission  {
      flex-grow: 1;
    }
  }

  > .canvas-link {
    margin-top: 8px;
    font-weight: 500;
  }

  > .ant-table-wrapper {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    margin: 16px 0;
  }
}

