.login-card {
  margin: 48px;
  min-width: 330px;

  .login-form {
    min-height: 320px;
  }

  .qr-container {
    text-align: center;
    margin: 1em;
  }

  .sign-in-options {
    text-align: center;
  }

  .login-form-button {
    width: 100%;
  }
}

html > body {
  /* Make the window transparent in LoginWidget */
  background: transparent;
}

.canvus-client {
  .login-card .login-form {
    min-height: 220px;
  }

  .ant-card {
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.24);
  }
}

section.canvus-client {
  background: transparent !important;
}

