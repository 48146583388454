.filter-buttons {
  margin-bottom: 16px;
}

.filter-input {
  margin-bottom: 16px;
}

.inline-button {
  margin-left: 16px;
  float: right;
}

