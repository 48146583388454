@import "~antd/lib/style/themes/default.less";

// Toolbar on the left side
.canvas-toolbar {
  height: calc(100vh - 64px);

  display: flex;
  flex-direction: column;

  // Remove border and shadow from toolbar buttons
  > .ant-btn {
    margin: 8px 0;
    border: none;
    box-shadow: none;
  }
}

// Upload file tool
.upload-files {
  > .title {
    text-align: center;
    font-weight: bold;
  }
}

// Upload note tool
.upload-notes {
  width: 400px;

  > .title {
    text-align: center;
  }

  > .hint {
    text-align: center;
  }

  .footer {
    display: flex;
    justify-content: center;
  }
}

.note-color-selector {
  display: flex;
  flex-direction: row;

  margin: 0 -16px;

  > .color {
    width: 40px;
    height: 40px;
    margin: 0 16px;

    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 1px;

    &.selected {
      border: 1px solid white;
      // Use box-shadow trick to achieve a second border
      box-shadow: 0 0 0 2px @primary-color;
    }
  }
}

