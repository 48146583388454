@import "~antd/lib/style/themes/default.less";

div.header-container {
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding-right: 8px;

  > .ant-dropdown-trigger {
    color: @menu-dark-color;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 46px;

    font-size: 32px;

    &:hover {
      background-color: @menu-dark-item-hover-bg;
      color: white;
    }

    &.ant-dropdown-open {
      background-color: @menu-dark-item-active-bg;
      color: @menu-dark-selected-item-text-color;
    }
  }

  > .canvas-name {
    color: white;
  }
}

.canvas-page-main-menu {
  // We use Menu.ItemGroup for achieve layout, but we don't want a title for the
  // group.
  .ant-dropdown-menu-item-group-title {
    display: none;
  }
}

